
import React from "react";
import { Nav } from "react-bootstrap";
import { NavLink, useLocation } from "react-router-dom";

const Navbar = () => {
  const location = useLocation();

  const isAdmin =
    JSON.parse(localStorage.getItem("user_info_MN"))?.status === 2;
  const isPendingUser =
    JSON.parse(localStorage.getItem("user_info_MN"))?.status === 0;

  return (
    <Nav className="navbar">
      <NavLink
        to="/"
        exact={"true"}
        className="nav-link"
      >
        <i>
          <img src={`${process.env.PUBLIC_URL}/img/dtb-logo.svg`} alt="Logo"  className="home-icon" />
        </i>
      </NavLink>
      {/* {!isPendingUser && location.pathname.includes("offer-management") && (
        <NavLink
          to="/offer-management"
          className="nav-link"
          activeclassname={styles.active}
          onClick={(event) => event.preventDefault()}
        >
          <OverlayTrigger placement="right" overlay={tooltipOfferManagement}>
            <i>
              <Fan alt="Offer Management" className={styles.icon} />
            </i>
          </OverlayTrigger>
        </NavLink>
      )} */}
      {/* <NavLink
        to="/logout"
        className={`nav-link ${styles["bottom-0"]}`}
        onClick={(e) => e.preventDefault()}
        activeclassname={styles.active}
      >
        <SignOutButton />
        <img src={logoutIcon} alt="Logout" className={styles.icon} />
      </NavLink> */}
    </Nav>
  );
};

export default Navbar;
