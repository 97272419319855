import React from "react";
import POCS from "../data/pocs";

function Showcase() {
  return (
    <div className="container-showcase custom-shadow">
      <div className="showcase-heading-container">
        <h1 className="showcase-heading">
          Innovative AI, Redefining Conversations
        </h1>
        <p className="showcase-content">
          Human-like Conversations that drive business success, Let innovation
          redefine how your business communicates and operates.
        </p>
      </div>
      <div className="d-flex flex-wrap justify-content-center gap-2">
        {POCS.map((el, index) => (
          <div
            className={`feature d-flex flex-column justify-content-between ${el.className}`}
            key={index}
          >
            <div>
              <div className="d-flex justify-content-between">
                <h2>{el.title}</h2>
                <img
                  src={`${process.env.PUBLIC_URL}/img/${el.iconName}.svg`}
                  alt="POC Icon"
                  className="poc-icon"
                />
              </div>
              <p>{el.text}</p>
            </div>
            <a
              href={el.link}
              className="btn btn-sm btn-primary btn-view-demo"
              target="_blank"
              rel="noopener noreferrer"
            >
              View Demo
            </a>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Showcase;
