import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "../_css/Hero.css";
import useViewPortWidth from "./hooks/useViewPortWidth";
import BackgroundBoxes from "./common/BackgroundBoxes";

function Hero() {
  const { viewPortWidth, percentageWidth } = useViewPortWidth();

  return (
    // <div className="hero">
    //   <h1>Define your Future with True Potential of Data</h1>
    //   <p className="subhero">
    //     Enabling data-driven decisions for sustainable success
    //   </p>
    //   </div>
    <section className="hero-feature-section py-5">
      {viewPortWidth > 768 && <BackgroundBoxes />}
      <Container className="text-center">
        {viewPortWidth > 768 && (
          <Row>
            <Col
              md={6}
              className="d-flex justify-content-center align-items-center"
              onClick={() => window.open('https://dataquery.datatobiz.com', '_blank', 'noopener,noreferrer')}
            >
              <div className="">
                <img
                  src={`${process.env.PUBLIC_URL}/img/data-query.svg`}
                  alt="Data Query Icon"
                  className="hero-feature-icon"
                />
                <p className="data-query-icon-text">Data Query</p>
              </div>
            </Col>
            <Col
              md={6}
              className="d-flex justify-content-center align-items-center"
              onClick={() => window.open('https://resumeanalyser.datatobiz.com', '_blank', 'noopener,noreferrer')}
            >
              <div className="">
                <img
                  src={`${process.env.PUBLIC_URL}/img/resume-analyser.svg`}
                  alt="Resume Analyse Icon"
                  className="hero-feature-icon"
                />
                <p className="resume-analyser-icon-text">Resume Analyse</p>
              </div>
            </Col>
          </Row>
        )}

        <h1 className="hero-section-title mt-4">
          Define your Future with True Potential of Data
        </h1>
        <p className="hero-section-subtitle">
          Enabling data-driven decisions for sustainable success
        </p>

        <Row className="center-elements">
          {viewPortWidth < 769 && (
            <>
              <Col
                md={4}
                className="d-flex justify-content-center align-items-center width-auto"
                onClick={() => window.open('https://dataquery.datatobiz.com', '_blank', 'noopener,noreferrer')}
              >
                <div className="">
                  <img
                    src={`${process.env.PUBLIC_URL}/img/data-query.svg`}
                    alt="Data Query Icon"
                    className="hero-feature-icon"
                  />
                  <p className="data-query-icon-text">Data Query</p>
                </div>
              </Col>
              <Col
                md={4}
                className="d-flex justify-content-center align-items-center width-auto"
                onClick={() => window.open('https://resumeanalyser.datatobiz.com', '_blank', 'noopener,noreferrer')}
              >
                <div className="">
                  <img
                    src={`${process.env.PUBLIC_URL}/img/resume-analyser.svg`}
                    alt="Resume Analyse Icon"
                    className="hero-feature-icon"
                  />
                  <p className="resume-analyser-icon-text">Resume Analyse</p>
                </div>
              </Col>
            </>
          )}
          <Col
            md={4}
            className="d-flex justify-content-center align-items-center width-auto"
            onClick={() => window.open('https://companionai.datatobiz.com', '_blank', 'noopener,noreferrer')}
          >
            <div className="">
              <img
                src={`${process.env.PUBLIC_URL}/img/companion.svg`}
                alt="CompanionAI Icon"
                className="hero-feature-icon"
              />
              <p className="companion-ai-icon-text">CompanionAI</p>
            </div>
          </Col>
          <Col
            md={4}
            className="d-flex justify-content-center align-items-center position-relative width-auto"
            onClick={() => window.open('https://finbot.datatobiz.com', '_blank', 'noopener,noreferrer')}
          >
            <div className="finbot-icon-hero-box">
              <img
                src={`${process.env.PUBLIC_URL}/img/finbot.svg`}
                alt="FinBot Icon"
                className="hero-feature-icon"
              />
              <p className="finbot-icon-text">FinBot</p>
            </div>
          </Col>
          <Col
            md={4}
            className="d-flex justify-content-center align-items-center width-auto"
            onClick={() => window.open('https://voicebot.datatobiz.com', '_blank', 'noopener,noreferrer')}
          >
            <div className="">
              <img
                src={`${process.env.PUBLIC_URL}/img/voice-bot.svg`}
                alt="Voice Bot Icon"
                className="hero-feature-icon"
              />
              <p className="voice-bot-icon-text">Voice Bot</p>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default Hero;
