import React from "react";
import "../_css/Home.css";
import Showcase from "../_components/Showcase";
import Hero from "../_components/Hero";

const Home = () => {
  return (
    <div className="mt-5 mb-5">
      <Hero />
      <Showcase />
    </div>
  );
};

export default Home;
