import "./App.css";
import { Route, Routes } from "react-router-dom";
import ProtectedRoutesOutlet from "./_routes/ProtectedRotuesOutlet";
import ProtectedRotues from "./_routes/ProtectedRoutes";
import LoginPage from "./_pages/LoginPage";
import { Toaster } from "react-hot-toast";
import LogoutPage from "./_pages/LogoutPage";

function App() {

  return (
    <>
      <Toaster
        position="top-right"
        containerClassName="hot-toaster-container"
      />
      <Routes>
        <Route path="/" element={<ProtectedRoutesOutlet />}>
          {ProtectedRotues?.map((route) => (
            <Route key={route.path} path={route.path} element={route.element} />
          ))}
        </Route>
        <Route key={`unique_121`} path="/login" element={<LoginPage />}></Route>
        <Route
          key={`unique_122`}
          path="/logout"
          element={<LogoutPage />}
        ></Route>
        <Route path="*" element={<p>404 Not Found!</p>} />
      </Routes>
    </>
  );
}

export default App;
