const POCS = [
    {
        "title": "Data Query",
        "text": "Leverages advanced data querying across sales, customer, and store datasets to deliver actionable insights. By intelligently analyzing key metrics, it helps your business make data-driven decisions, optimize sales strategies, and enhance customer experiences through accurate, real-time results.",
        "link": "https://dataquery.datatobiz.com/",
        "className": "data-query",
        "iconName": "data-query",
    },
    {
        "title": "CompanionAI",
        "text": "Designed to offer emotional support, provide thoughtful advice, and be a comforting presence during times of need. Whether you're seeking guidance, a listening ear, or just some encouragement, this AI is here to help you navigate through life's challenges with empathy and understanding.",
        "link": "https://companionai.datatobiz.com/",
        "className": "companion-ai",
        "iconName": "companion",
    },
    {
        "title": "Resume Analyzer",
        "text": "Allows users to upload their resumes in doc format and receive detailed insights. By analyzing key skills, experience, and qualifications, it provides actionable feedback to enhance your resume, helping you stand out in competitive job markets.",
        "link": "https://resumeanalyser.datatobiz.com/",
        "className": "resume-analyzer",
        "iconName": "resume-analyser",
    },
    {
        "title": "Voice Bot",
        "text": "Brings iconic personalities like Elon Musk and Indra Nooyi to life by providing comprehensive information about their careers, achievements, and life stories. It allows users to interact and learn about influential figures in an engaging, conversational manner, making knowledge more accessible and dynamic.",
        "link": "https://voicebot.datatobiz.com/",
        "className": "voice-bot",
        "iconName": "voice-bot",
    },
    {
        "title": "FinBot",
        "text": "FinBot is an intelligent financial assistant that queries and analyzes financial data to provide critical insights, including profit, revenue, and other key financial metrics. Designed to simplify financial analysis, it delivers real-time, data-driven outputs to help businesses make informed financial decisions effortlessly.",
        "link": "https://finbot.datatobiz.com/",
        "className": "finbot",
        "iconName": "finbot",
    }
]

export default POCS;