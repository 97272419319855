// BackgroundBoxes.js
import React from 'react';
import "../../_css/BackgroundBoxes.css"; // Custom CSS for the background

const BackgroundBoxes = () => {
  return (
    <div className="background-boxes">
      {/* Adding 4 rows and each row contains 6 squares */}
      {[...Array(2)].map((_, rowIndex) => (
        <div className="row" key={rowIndex}>
          {[...Array(6)].map((_, colIndex) => (
            <div className="box" key={colIndex}></div>
          ))}
        </div>
      ))}
    </div>
  );
};

export default BackgroundBoxes;
