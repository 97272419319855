import { Navigate, Outlet } from "react-router-dom";
import Navbar from "../_components/common/Navbar";

const ProtectedRoutesOutlet = () => {
  // const auth = { token: localStorage.getItem('access_token_MN') };
  const auth = { token: "Authenticated" };
  return auth.token ? (
    <>
      {/* Header | Footer | Drawer for Protected Routes add here */}
      <header className="App-header">
        <Navbar />
      </header>
      <Outlet />
    </>
  ) : (
    <Navigate to="/login" />
  );
};

export default ProtectedRoutesOutlet;
