import Home from "../_pages/Home";

const ProtectedRotues = [
  {
    path: "/",
    element: <Home />,
  }
];

export default ProtectedRotues;
