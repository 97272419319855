import { useState, useEffect } from 'react';

const useViewPortWidth = (percentage = 100) => {
  const [viewPortWidth, setViewPortWidth] = useState(window.innerWidth);

  useEffect(() => {
    const updateWidth = () => {
      setViewPortWidth(window.innerWidth);
    };

    // Update the height on load and resize
    window.addEventListener('resize', updateWidth);

    // Cleanup the event listener
    return () => {
      window.removeEventListener('resize', updateWidth);
    };
  }, []);

  // Calculate the percentage of the viewport height
  const percentageWidth = (viewPortWidth * percentage) / 100;

  return { viewPortWidth, percentageWidth };
};

export default useViewPortWidth;
