import { createStore, applyMiddleware, combineReducers } from 'redux';
import { thunk } from 'redux-thunk';
import userReducer from './reducers/userReducer';
import loaderReducer from './reducers/loaderReducer';

// Combine reducers if you have multiple reducers
const rootReducer = combineReducers({
    user: userReducer, // Add the user reducer to the root reducer
    loader: loaderReducer,
    // Add other reducers here if needed
  });

const store = createStore(rootReducer, applyMiddleware(thunk));

export default store;
